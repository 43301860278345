// -- AUTOGENERATED --

// eslint-disable-next-line no-unused-vars
import APIUtils from '@/util/apiutil'

import Axios from 'axios'
import NETVSConfig from '@/../netvs.config'

/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable object-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable object-shorthand */
export default {

  createParamsList () {
    return {'old': [ ], 'new': [ 'login_name', 'description', 'expiration_date']}
  },
  create (config, {login_name_new, description_new, expiration_date_new}) {
    const params = { 'new': { 'login_name': login_name_new, 'description': description_new, 'expiration_date': expiration_date_new}, 'old': { }}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/cntl/wapi_auth/create`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  deleteParamsList () {
    return {'old': [ 'gpk'], 'new': [ ]}
  },
  delete (config, {gpk_old}) {
    const params = { 'new': { }, 'old': { 'gpk': gpk_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/cntl/wapi_auth/delete`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  list (config, { gpk, type, is_own, gpk_list, is_expired, login_name, fetch_limit, fetch_offset, filter_params_dict, sorting_params_list }) {
    const params = APIUtils.denullify_dict({ 'gpk': gpk, 'type': type, 'is_own': is_own, 'gpk_list': (gpk_list === null) ? null : JSON.stringify(gpk_list), 'is_expired': is_expired, 'login_name': login_name, 'fetch_limit': fetch_limit, 'fetch_offset': fetch_offset, 'filter_params_dict': filter_params_dict, 'sorting_params_list': (sorting_params_list === null) ? null : JSON.stringify(sorting_params_list)})
    const cnf = {}
    Object.assign(cnf, (config || {}).netdb_axios_config)
    cnf.params = params
    return Axios.get(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/cntl/wapi_auth/list`, cnf)
  // eslint-disable-next-line comma-dangle
  },
  updateParamsList () {
    return {'old': [ 'gpk'], 'new': [ 'description', 'expiration_date', 'do_refresh_token']}
  },
  update (config, {gpk_old, description_new, expiration_date_new, do_refresh_token_new}) {
    const params = { 'new': { 'description': description_new, 'expiration_date': expiration_date_new, 'do_refresh_token': do_refresh_token_new}, 'old': { 'gpk': gpk_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/cntl/wapi_auth/update`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
}
