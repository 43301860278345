import TransactionService from './transaction.service'

export default {
  list (config) {
    const ta = [
      { name: 'cntl.mgr.list', old: { is_own: true } }, // Accounts
      { name: 'cntl.wapi_auth.list', inner_join_ref: { mgr_list: 'default' } }, // Tokens
      { name: 'cntl.mgr2role.list', inner_join_ref: { mgr_list: 'default' } }, // Mgr2Role
      { name: 'cntl.role.list', inner_join_ref: { mgr2role_list: 'default' }, old: { is_role: true, sorting_params_list: ['position'] } }, // Roles
      { name: 'cntl.role.list', idx: 'subrole_list', inner_join_ref: { role_list: 'api_func_cntl_role_hierarchy_gfk_list_is_superset' }, old: { is_role: true } },
      { name: 'cntl.role.list', idx: 'perm_list', inner_join_ref: { role_list: 'api_func_cntl_role_hierarchy_gfk_list_is_superset' }, old: { is_role: false } },
      { name: 'cntl.mgr2group.list', old: { is_own: true } } // Groups
    ]
    return TransactionService.execute(config, ta)
  },
  update (config, { login_name_old, login_name_new, description_new, allow_data_manipulation_new, roles_to_add, roles_to_remove }) {
    const ta = [
      {
        name: 'cntl.mgr.update',
        new: {
          login_name: login_name_new,
          description: description_new,
          allow_data_manipulation: allow_data_manipulation_new
        },
        old: { login_name: login_name_old }
      },
      {
        name: 'cntl.mgr2role.bulk_update',
        new: { role_fq_name_list: roles_to_add },
        old: { role_fq_name_list: roles_to_remove, mgr_login_name: login_name_old }
      }
    ]
    return TransactionService.execute(config, ta)
  }
}
