<template>
  <div id="tokens">
    <h1 class="my-4">{{ $tc('system.subaccount', 2) }} & {{ $tc('system.api_token', 2) }}</h1>
    <b-row>
      <b-col lg="9">
        <b-input-group class="shadow">
          <b-input-group-prepend>
            <b-input-group-text>
              <netvs-icon icon="filter"></netvs-icon>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-form-input id="filter-input" v-model.trim="filter_text" :placeholder="$t('system.filter')"
                        autofocus debounce="300"/>
        </b-input-group>
      </b-col>
      <b-col lg="3" sm="*">
        <b-button block variant="outline-success" @click="createAccount" class="shadow">
          {{ $t('system.utils.create_template', { object: $tc('system.subaccount', 1) }) }}
        </b-button>
      </b-col>
    </b-row>
    <b-modal id="modal-edit-account" size="lg"
             :title="$t('system.utils.update_template', {object: $t('system.account')})"
             @hidden="resetAccountData">
      <b-form>
        <b-form-group :label="$t('system.description') + ':'" label-for="input-account-edit-description">
          <b-form-textarea
            id="input-account-edit-description"
            v-model.trim="new_account.description"
            :placeholder="$t('views.netdb.tokens.enter_description')"
          />
        </b-form-group>
        <template
          v-if="roles_by_account != null && $store.state.user != null && roles_by_account[$store.state.user.login_name] != null && assignable_role_name_options_by_system != null">
          <b-form-group :label="$t('views.netdb.tokens.roles')">
            <b-input-group>
              <b-form-select @change="updateCanAddRole" v-model="new_system"
                             :options="assignable_system_options"/>
              <b-form-select @change="updateCanAddRole" v-model="new_role_name"
                             :options="assignable_role_name_options_by_system[new_system]"/>
              <b-input-group-append>
                <b-button variant="success" :disabled="!can_add_role" @click="addRole">
                  <template v-if="cant_add_role_reason === ''">{{ $t('views.netdb.tokens.add_role') }}</template>
                  <span v-else class="text-danger">{{ cant_add_role_reason }}</span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <h5 class="my-1">
              <span
                :key="'role-badge-' + role.mgr_login_name + '-' + role.fq_name"
                v-for="role in new_account.roles">
                <b-button
                  :id="'create-account-role-badge-' + role.fq_name"
                  :style="{background: role.fq_name.toHSL({lit: [30, 40]}), border: 'none'}"
                  class="mr-1 mb-1 badge" tabindex="0" href="#">
                  <div class="d-flex align-items-center">
                    {{ role.system.toUpperCase() }}<br>{{ role.name }}
                    <span @click="removeRole(role)" class="p-2">
                      <netvs-icon style="opacity: 0.7" icon="clear"></netvs-icon>
                    </span>
                  </div>
                </b-button>
                <b-popover
                  :key="'role-badge-tooltip' + role.mgr_login_name + '-' + role.fq_name"
                  :target="'create-account-role-badge-' + role.fq_name"
                  triggers="focus" custom-class="popover-wide"
                  placement="bottom">
                  <b-table :fields="permission_list_fields"
                           :items="Object.values(permissions).filter(p => p.hierarchy_gfk_list.includes(role.gpk))"
                           sticky-header/>
                </b-popover>
              </span>
              <b-button v-if="new_account.roles.length > 0" variant="outline-danger" block class="mt-1"
                        @click="removeAllRoles">
                {{ $t('views.netdb.tokens.remove_all_roles') }}
              </b-button>
            </h5>
          </b-form-group>
        </template>
      </b-form>
      <template v-slot:modal-footer="{cancel}">
        <b-alert id="alert-edit-account" v-model="show_modal_alert"
                 variant="danger" dismissible fade class="mb-0 flex-grow">
          {{ modal_alert_content }}
        </b-alert>
        <b-button variant="outline-secondary" @click="cancel">
          {{ $t('system.cancel') }}
        </b-button>
        <b-button variant="primary" :disabled="waiting" @click="editAccount">
          {{ $t('views.netdb.tokens.save_changes') }}
        </b-button>
      </template>
    </b-modal>
    <Loading :data="data_fully_loaded">
        <b-card v-for="account in filtered_accounts" no-body :key="'card-account-' + account.login_name" class="mb-4 shadow">
          <template v-slot:header>
            <b-row>
              <b-col lg="3">
                <h4>
                  {{ account.login_name }}
                </h4>
                <p class="text-muted">{{ $t('views.netdb.tokens.login_name') }}</p>
              </b-col>
              <b-col>
                <div v-if="account.parent_login_name === account.login_name">
                  {{ $t('views.netdb.tokens.main_account_note') }}
                </div>
                <div v-else-if="account.description === '' || account.description == null">
                  {{ $t('views.netdb.tokens.no_description_found') }}
                </div>
                <div v-else>{{ account.description }}</div>
                <p class="text-muted">{{ $t('system.description') }}</p>
              </b-col>
              <b-col>
                <template v-if="roles_by_account[account.login_name] != null && assignable_roles_by_fq_name != null">
                  <h5 class="mb-0">
                      <span
                        v-for="role in expand_roles_by_account[account.login_name] ? roles_by_account[account.login_name] : roles_by_account[account.login_name].slice(0, max_role_badge_count)"
                        :key="'role-badge-' + role.mgr_login_name + '-' + role.role_fq_name">
                        <b-button
                          :id="account.login_name + '-role-badge-' + role.mgr_login_name + '-' + role.role_fq_name"
                          :style="{background: role.role_fq_name.toHSL({lit: [30, 40]}), border: 'none'}"
                          class="mr-1 mb-1 badge" tabindex="0" href="#">{{ role.system.toUpperCase() }}<br>{{
                            role.role
                          }}
                        </b-button>
                        <b-popover
                          :target="account.login_name + '-role-badge-' + role.mgr_login_name + '-' + role.role_fq_name"
                          triggers="focus" custom-class="popover-wide"
                          placement="bottom">
                          <b-table :fields="permission_list_fields"
                                   :items="Object.values(permissions).filter(p => p.hierarchy_gfk_list.includes(subroles[role.role_fq_name]?.gpk))"
                                   sticky-header/>
                        </b-popover>
                      </span>
                  </h5>
                  <template v-if="roles_by_account[account.login_name].length > max_role_badge_count">
                    <b-link @click="$set(expand_roles_by_account,account.login_name, true)"
                            v-if="!expand_roles_by_account[account.login_name]">
                      + {{ roles_by_account[account.login_name].length - max_role_badge_count }}
                      {{ $t('views.netdb.tokens.additional') }}
                    </b-link>
                    <b-link @click="$set(expand_roles_by_account,account.login_name, false)"
                            v-else>{{ $t('views.netdb.tokens.show_less') }}
                    </b-link>
                  </template>
                </template>
                <h6 v-else>{{ $t('views.netdb.tokens.no_roles_assigned') }}</h6>
                <p class="text-muted">{{ $t('views.netdb.tokens.roles_global_rights') }}</p>
              </b-col>
              <b-col lg="2">
                <template v-if="checkRights($store.state, 'cntl.admin') || account.parent_login_name !== account.login_name">
                  <b-button-group class="d-flex">
                    <b-button v-if="account.parent_login_name !== account.login_name"
                              variant="outline-secondary"
                              :id="'tokens_impersonate-' + account.login_name"
                              @click="$store.commit('updateImpersonatingUser', account.login_name); $store.commit('reloadRouterComp')">
                      <netvs-icon icon="impersonate"></netvs-icon>
                    </b-button>
                    <b-tooltip :target="'tokens_impersonate-' + account.login_name" triggers="hover" variant="secondary"
                               placement="bottom">
                      {{
                        $t('components.svc_card.impersonate')
                      }}
                    </b-tooltip>
                    <b-button variant="outline-primary"
                              :id="'button-edit-account-' + account.login_name"
                              @click="showModalEditAccount(account)">
                      <netvs-icon icon="edit"></netvs-icon>
                    </b-button>
                    <b-tooltip placement="bottom" :target="'button-edit-account-' + account.login_name"
                               triggers="hover" variant="primary">
                      {{ $t('system.utils.update_template', { object: $t('system.account') }) }}
                    </b-tooltip>
                    <b-button :disabled="account.parent_login_name === account.login_name" @click="deleteAccount(account)" variant="outline-danger"
                              :id="'button-delete-account-' +  account.login_name">
                      <netvs-icon icon="delete"></netvs-icon>
                    </b-button>
                    <b-tooltip :target="'button-delete-account-' +  account.login_name" triggers="hover"
                               variant="danger" placement="bottom">
                      {{ $t('system.utils.delete_template', { object: $t('system.account') }) }}
                    </b-tooltip>
                  </b-button-group>
                </template>
              </b-col>
            </b-row>
          </template>
          <b-button block squared variant="outline-secondary" v-b-toggle="account.login_name + '-tokens-collapse'">
            <netvs-icon icon="token"></netvs-icon>
            {{ $tc('system.token', 2) }}
            <b-badge pill v-if="tokens_by_account[account.login_name]">{{
                tokens_by_account[account.login_name].length
              }}
            </b-badge>
            <b-badge pill v-else>0</b-badge>
            <br/>
            <netvs-icon class="collapse-icon" icon="collapse"></netvs-icon>
          </b-button>
          <b-collapse :id="account.login_name + '-tokens-collapse'">
            <b-table :items="tokens_by_account[account.login_name]"
                     :fields="token_list_fields"
                     class="m-0"
                     striped
                     responsive
                     primary-key="description">
              <template v-slot:head(buttons)>
                <template v-if="account.parent_login_name !== account.login_name">
                  <b-button block variant="outline-success"
                            :id="'button-create-token-' +  account.login_name"
                            @click="showModalCreateToken(account.login_name)">
                    <netvs-icon icon="create"></netvs-icon>
                  </b-button>
                  <b-tooltip :target="'button-create-token-' +  account.login_name" triggers="hover"
                             variant="success" placement="left">
                    {{ $t('system.utils.create_template', { object: $tc('system.token', 1) }) }}
                  </b-tooltip>
                </template>
              </template>
              <template v-slot:cell(expiration_date)="data">
                {{ formatDate(data.item.expiration_date) }}
                <template v-if="data.item.is_expired">
                  {{ $t('views.netdb.tokens.expired') }}
                </template>
              </template>
              <template v-slot:cell(description)="data">
                {{ data.item.description }}
                <b-badge :id="'badge-token' + data.item.gpk" variant="primary">{{ data.item.type }}</b-badge>
                <b-tooltip :target="'badge-token' + data.item.gpk"
                           triggers="hover" variant="primary" placement="right">
                  {{ data.item.type_description }}
                </b-tooltip>
              </template>
              <template v-slot:cell(buttons)="data">
                <b-button-group class="d-flex">
                  <b-button @click="deleteToken(data.item)" variant="outline-danger"
                            :id="'button-delete-token-' +  data.item.gpk">
                    <netvs-icon icon="delete"></netvs-icon>
                  </b-button>
                  <b-tooltip :target="'button-delete-token-' +  data.item.gpk" triggers="hover"
                             variant="danger" placement="left">
                    {{ $t('system.utils.delete_template', { object: $tc('system.token', 1) }) }}
                  </b-tooltip>
                  <b-button variant="outline-primary" v-if="account.parent_login_name !== account.login_name"
                            :id="'button-edit-token-' + account.login_name+ '-' + data.index"
                            @click="showModalEditToken(data.item)">
                    <netvs-icon icon="edit"></netvs-icon>
                  </b-button>
                  <b-tooltip :target="'button-edit-token-' + account.login_name+ '-' + data.index"
                             triggers="hover" variant="primary" placement="left"
                             v-if="account.parent_login_name !== account.login_name">
                    {{ $t('system.utils.update_template', { object: $tc('system.token', 1) }) }}
                  </b-tooltip>
                </b-button-group>
              </template>
            </b-table>
          </b-collapse>
          <b-button block squared variant="outline-secondary" v-b-toggle="account.login_name + '-groups-collapse'">
            <netvs-icon icon="groups"></netvs-icon>
            {{ $tc('system.group', 2) }}
            <b-badge pill v-if="groups_by_account[account.login_name]">{{
                groups_by_account[account.login_name].length
              }}
            </b-badge>
            <b-badge pill v-else>0</b-badge>
            <br/>
            <netvs-icon class="collapse-icon" icon="collapse"></netvs-icon>
          </b-button>
          <b-collapse :id="account.login_name + '-groups-collapse'">
            <Groups :viewing_mgr="account.login_name" class="px-3" :show_header="false" :show_add_group="false" :show_sub_groups="true"/>
          </b-collapse>
        </b-card>
      <DBEditor object_fq_name="cntl.wapi_auth" modal_id="token_editor" :object_function="db_editor_function"
                :old_data="db_editor_old_data"
                :show_result="true"
                :presets="db_editor_presets"
                :non_optionals_order="['login_name', 'description', 'expiration_date', 'do_refresh_token']"
      ></DBEditor>
      <DBEditor object_fq_name="cntl.mgr" modal_id="account_editor" :object_function="db_editor_function"
                :old_data="db_editor_old_data"
                :presets="db_editor_presets"
                :non_optionals_order="['description', 'do_copy_roles', 'allow_data_manipulation']"
      ></DBEditor>
    </Loading>
  </div>
</template>

<script>
import AccountTokenService from '@/api-services/account_token.service'
import AccountService from '@/api-services.gen/cntl.mgr'
import TokenService from '@/api-services.gen/cntl.wapi_auth'
import ApiUtil from '@/util/apiutil'
import '@/util/colorutil'
import RoleService from '@/api-services.gen/cntl.role'
import Loading from '../../components/Loading'
import DBEditor from '@/components/db-editor/APIObjectDBEditor.vue'
import transactionutil from '@/util/transactionutil'
import Groups from '@/views/cntl/Groups'
import dateutil from '../../util/dateutil'

export default {
  name: 'tokenView',
  components: { Groups, DBEditor, Loading },
  data() {
    return {
      db_editor_old_data: null,
      db_editor_presets: {},
      db_editor_function: 'update',
      tokens_by_account: null,
      groups_by_account: null,
      roles_by_account: null,
      assignable_roles: null,
      assignable_roles_by_fq_name: null,
      assignable_roles_by_system: null,
      assignable_system_options: null,
      assignable_role_name_options_by_system: null,
      accounts: null,
      new_account: {
        parent_login_name: null,
        description: '',
        login_name: '',
        expiration_date: null,
        do_copy_roles: true,
        roles: []
      },
      filter_text: '',
      show_modal_alert: true,
      modal_alert_content: '',
      waiting: false,
      token: '',
      max_role_badge_count: 5,
      expand_roles_by_account: {},
      new_system: '',
      new_role_name: '',
      can_add_role: false,
      cant_add_role_reason: '',

      permissions: {},
      subroles: {}
    }
  },
  computed: {
    token_list_fields() {
      return [
        {
          key: 'description',
          label: this.$t('system.description'),
          sortable: true
        },
        {
          key: 'expiration_date',
          label: this.$t('views.netdb.tokens.expiration_date'),
          sortable: true
        },
        {
          key: 'delete_date',
          label: this.$t('views.netdb.tokens.deletion_date'),
          sortable: true
        },
        {
          key: 'last_login_date',
          label: this.$t('views.netdb.tokens.last_used'),
          formatter: this.formatDate,
          sortable: true
        },
        {
          key: 'last_generate_date',
          label: this.$t('views.netdb.tokens.last_generated'),
          formatter: this.formatDate,
          sortable: true
        },
        {
          key: 'buttons',
          label: '',
          sortable: false
        }
      ]
    },
    group_list_fields() {
      return [
        {
          key: 'group_name',
          label: this.$t('views.netdb.tokens.group_name'),
          sortable: true
        },
        {
          key: 'buttons',
          label: '',
          sortable: false
        }
      ]
    },
    permission_list_fields() {
      return [
        {
          key: 'system',
          label: this.$t('views.netdb.tokens.system')
        },
        {
          key: 'name',
          label: this.$t('views.netdb.tokens.permission')
        }
      ]
    },
    filtered_accounts() {
      if (this.accounts == null) {
        return null
      }
      if (this.filter_text === '') {
        return this.accounts
      }
      return this.accounts.filter(account => {
        return account.login_name.toLowerCase().includes(this.filter_text.toLowerCase()) ||
          (account.description != null && account.description.toLowerCase().includes(this.filter_text.toLowerCase()))
      })
    },
    data_fully_loaded() {
      return this.filtered_accounts !== null && this.assignable_system_options !== null
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    checkRights: ApiUtil.checkPermission,
    async fetchData() {
      const response = await AccountTokenService.list(this.$store.state, this.$store.state.user?.login_name)
      this.accounts = response.data.mgr_list
      this.accounts.sort((a, b) => {
        return a.parent_login_name === a.login_name ? -1 : b.parent_login_name === b.login_name ? 1 : 0
      })
      this.tokens_by_account = ApiUtil.dict_of_lists_by_value_of_array(this.formatExpiredTokens(response.data.wapi_auth_list), 'login_name')
      this.groups_by_account = ApiUtil.dict_of_lists_by_value_of_array(response.data.mgr2group_list, 'mgr_login_name')
      this.roles_by_account = ApiUtil.dict_of_lists_by_value_of_array(response.data.mgr2role_list, 'mgr_login_name')
      this.permissions = ApiUtil.dict_by_value_of_array(response.data.perm_list, 'fq_name')
      this.subroles = ApiUtil.dict_by_value_of_array(response.data.subrole_list, 'fq_name')

      let assignable_role_names = new Set()
      response.data.role_list.forEach(role => {
        assignable_role_names.add(role.fq_name)
        response.data.subrole_list.filter(r => r.hierarchy_gfk_list.includes(role.gpk)).forEach(subrole => {
          assignable_role_names.add(subrole.fq_name)
        })
      })

      const has_perm = this.role_list_has_permission(response.data.role_list, 'cntl.admin')

      if (has_perm) { // TODO: fix condition
        // const r = await AccountTokenService.list_all_roles(this.$store.state)
        // this.assignable_roles = r.data.role_list
        assignable_role_names = null
      }

      RoleService.list(this.$store.state, {
        is_role: true,
        fq_name_list: assignable_role_names ? Array.from(assignable_role_names) : null
      }).then(response => {
        this.assignable_roles = response.data[0]
        this.assignable_roles_by_fq_name = ApiUtil.dict_of_lists_by_value_of_array(this.formatContainedPermissions(this.assignable_roles), 'fq_name')
        this.assignable_roles_by_system = ApiUtil.dict_of_lists_by_value_of_array(this.assignable_roles, 'system')
        this.assignable_system_options = new Set()
        this.assignable_role_name_options_by_system = {}
        Object.keys(this.assignable_roles_by_system).forEach(system => {
          this.assignable_system_options.add({ value: system, text: system.toUpperCase() })
          this.assignable_roles_by_system[system].sort((a, b) => {
            if (a.position < b.position) return -1
            if (a.position > b.position) return 1
            return 0
          })
          this.assignable_role_name_options_by_system[system] = []
          this.assignable_roles_by_system[system].forEach(role => {
            this.assignable_role_name_options_by_system[system].push({ value: role.name, text: role.name })
          })
        })
        this.assignable_system_options = Array.from(this.assignable_system_options)
      })
    },
    role_list_has_permission(role_list, permission_name) {
      return role_list.some(role => {
        if (!(permission_name in this.permissions)) {
          return false
        }
        return this.permissions[permission_name].hierarchy_gfk_list.some(gfk => {
          return role.gpk === gfk
        })
      })
    },
    showModalEditAccount(account) {
      this.new_system = ''
      this.new_role_name = ''
      this.new_account.description = account.description
      this.new_account.login_name = account.login_name
      this.new_account.parent_login_name = account.parent_login_name
      this.new_account.roles = []
      if (this.roles_by_account[account.login_name]) {
        this.roles_by_account[account.login_name].forEach(role => {
          this.new_account.roles.push(this.assignable_roles_by_fq_name[role.role_fq_name][0])
        })
      }
      this.updateCanAddRole()
      this.show_modal_alert = false
      this.$bvModal.show('modal-edit-account')
    },
    showModalEditToken(token) {
      this.db_editor_old_data = this.db_editor_presets = token
      this.db_editor_function = 'update'
      this.$bvModal.show('token_editor')
    },
    showModalCreateToken(login_name) {
      this.db_editor_old_data = {}
      this.db_editor_presets = { login_name: login_name }
      this.db_editor_function = 'create'
      this.$bvModal.show('token_editor')
    },
    resetAccountData() {
      this.new_account = {
        parent_login_name: null,
        description: '',
        login_name: '',
        expiration_date: null,
        do_copy_roles: true,
        roles: []
      }
      this.new_system = ''
      this.new_role_name = ''
    },
    resetTokenData() {
      this.new_token = {
        description: '',
        login_name: '',
        expiration_date: null
      }
    },
    createAccount() {
      this.db_editor_old_data = this.db_editor_presets = {}
      this.db_editor_function = 'create'
      this.$bvModal.show('account_editor')
    },
    editAccount() {
      this.waiting = true
      const old_roles = []
      if (this.roles_by_account[this.new_account.login_name]) {
        this.roles_by_account[this.new_account.login_name].forEach(role => {
          old_roles.push(role.role_fq_name)
        })
      }
      const new_roles = []
      this.new_account.roles.forEach(role => {
        new_roles.push(role.fq_name)
      })
      const roles_to_add = new_roles.filter(x => !old_roles.includes(x))
      const roles_to_remove = old_roles.filter(x => !new_roles.includes(x))
      AccountTokenService.update(this.$store.state, {
        description_new: this.new_account.description,
        login_name_old: this.new_account.login_name,
        allow_data_manipulation_new: true,
        roles_to_add: roles_to_add,
        roles_to_remove: roles_to_remove
      }).then(() => {
        this.$bvModal.hide('modal-edit-account')
        this.fetchData()
        this.waiting = false
      }).catch(error => {
        this.show_modal_alert = true
        this.waiting = false
        this.modal_alert_content = error.response.data.exception.error.details
      })
    },
    deleteAccount(item) {
      const ta = transactionutil.generateDeleteElement('cntl.mgr', AccountService.deleteParamsList(), item, this.$tc('system.subaccount', 1) + ' ' + item.login_name)
      this.$store.commit('addTransactionElement', ta)
      this.$emit('commited', ta)
      if (!this.isMobile() && !this.$store.state.show_sidebar_right) {
        this.$store.commit('showSidebarRight', true)
      }
    },
    deleteToken(item) {
      const ta = transactionutil.generateDeleteElement('cntl.wapi_auth', TokenService.deleteParamsList(), item, this.$t('views.netdb.tokens.token_from_subaccount') + ' ' + item.login_name)
      this.$store.commit('addTransactionElement', ta)
      this.$emit('commited', ta)
      if (!this.isMobile() && !this.$store.state.show_sidebar_right) {
        this.$store.commit('showSidebarRight', true)
      }
    },
    formatDate(value) {
      if (value == null) {
        return 'N/A'
      }
      return dateutil.format_date(new Date(Date.parse(value)))
    },
    formatExpiredTokens(tokens) {
      tokens.forEach(token => {
        if (token.is_expired) {
          token._rowVariant = 'danger'
        }
      })
      return tokens
    },
    formatContainedPermissions(roles) {
      roles.forEach(role => {
        if (role.contained_permissions) {
          const permissions = []
          for (const perm of Object.values(this.permissions).filter(p => p.hierarchy_gfk_list.includes(role.gpk))) {
            permissions.push({ system: perm.system, permission: perm.name })
          }
          // yes, this method synthesizes an attribute in the role; this is intentional
          role.contained_permissions = permissions
        }
      })
      return roles
    },
    addRole(e) {
      e.preventDefault()
      const new_role = this.new_system + '.' + this.new_role_name
      this.new_account.roles.push(this.assignable_roles_by_fq_name[new_role][0])
      this.updateCanAddRole()
    },
    removeRole(role) {
      const index = this.new_account.roles.indexOf(role)
      if (index > -1) {
        this.new_account.roles.splice(index, 1)
      }
      this.updateCanAddRole()
    },
    removeAllRoles() {
      this.new_account.roles = []
      this.updateCanAddRole()
    },
    updateCanAddRole() {
      const new_role = this.new_system + '.' + this.new_role_name
      if (!this.new_system || !this.new_role_name || !this.assignable_roles_by_fq_name[new_role]) {
        this.can_add_role = false
        this.cant_add_role_reason = this.$t('views.netdb.tokens.select_system_role')
        return
      }
      let valid = true
      this.new_account.roles.forEach(role => {
        if (role.fq_name === new_role) {
          valid = false
          this.cant_add_role_reason = this.$t('views.netdb.tokens.role_already_added')
        } else if (this.subroles[new_role]?.hierarchy_gfk_list.includes(role.gpk)) {
          valid = false
          this.cant_add_role_reason = role.fq_name + ' ' + this.$t('views.netdb.tokens.contains') + ' ' + new_role
        }
      })

      this.can_add_role = valid
      if (valid) {
        this.cant_add_role_reason = ''
      }
    }
  }
}
</script>

<style>
.b-form-tag {
  font-size: 100%;
  font-weight: normal;
  line-height: 1.5;
}
</style>
